type social = {
  label: string;
  url: string;
  icon: string;
}

export const SOCIAL:social[] = [
  {
    label: "instagram",
    url: "https://www.instagram.com/plaenco/",
    icon: "fa-instagram"
  },
  {
    label: "tiktok",
    url: "https://www.tiktok.com/@plaenco",
    icon: "fa-tiktok"
  },
  {
    label: "youtube",
    url: "https://www.youtube.com/@plaenco",
    icon: "fa-youtube"
  },
  {
    label: "twitter",
    url: "https://twitter.com/Plaenco",
    icon: "fa-twitter"
  },
]