import Link from 'next/link';
import styled from 'styled-components';

export const TileContainer = styled(Link)`
  border-radius: 0.4rem;
  transition: all 0.2s cubic-bezier(0.77, 0, 0.175, 1);
  overflow: hidden;
  position: relative;

  @media ${({ theme }) => theme.mediaQuery.smallDesktop} {
    border-radius: 1rem;
  }

  &:hover {
    border-radius: 0;

    h2 {
      opacity: 1;
      transform: translate(0);
    }

    .overlay {
      background: rgba(0, 0, 0, 0.11);
    }
  }

  .img-container {
    aspect-ratio: 16/9;
    position: relative;
  }

  .overlay {
    position: absolute;
    z-index: 1;
    inset: 0;
    background: rgba(0, 0, 0, 0);
    transition: all 0.3s cubic-bezier(0.77, 0, 0.175, 1);
  }

  h2 {
    position: absolute;
    z-index: 2;
    bottom: 1rem;
    left: 1.5rem;
    color: white;
    opacity: 0;
    transform: translateY(30%);
    transition: opacity 0.2s cubic-bezier(0.77, 0, 0.175, 1), transform 0.2s cubic-bezier(0.77, 0, 0.175, 1);
  }
`