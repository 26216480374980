import Image from 'next/image';
import { LinkData } from "@/app/homeLayout";
import { TileContainer } from "./styles";

type FeatureTileProps = Omit<LinkData, "hidden">;

const FeatureTile = ({
  title,
  img,
  link
}: FeatureTileProps) => (
  <TileContainer href={link}>
    <h2 className="h3">{title}</h2>
    <div className="overlay" />
    <div className="img-container">
      {typeof img === 'string' ? (
        <Image src={img} loading="lazy" quality={75} fill={true} alt={title} />
      ) : (
        <Image placeholder="blur" src={img} loading="lazy" quality={75} fill={true} alt={title} />
      )}
    </div>
  </TileContainer>
);

export default FeatureTile;